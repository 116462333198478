<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">Staking User</h1>

    <div v-if="stakingUser" class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Email</p>
                <h2 class="headline--md mt-4">{{ stakingUser.user.email }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Full Name</p>
                <h2 class="headline--md mt-4">{{ stakingUser.user.fullName }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Wallet Address</p>
                <div class="clipboard-copy">
                  <h2 class="headline--md mt-4 clipboard-copy__text">
                    <span class="clipboard-copy__text-inner">{{ stakingUser.walletAddress }}</span>
                    <copy-clipboard
                      @on-copy="doCopy($event)"
                      :external-link="`/etherscan-address/${stakingUser.walletAddress}`"
                    />
                  </h2>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Deposited Amount (TOKO)</p>
                <h2 class="headline--md mt-4">{{ stakingUser.depositedAmount | float_number_with_commas }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Withdraw Amount (TOKO)</p>
                <h2 class="headline--md mt-4">{{ stakingUser.withdrawAmount | float_number_with_commas }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Rate (%)</p>
                <h2 class="headline--md mt-4">{{ stakingUser.rate | rate_percent }}</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Start Date</p>
                <h2 class="headline--md mt-4">{{ stakingUser.startAt | moment_lts }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">End Date</p>
                <h2 class="headline--md mt-4">{{ stakingUser.endAt | moment_lts }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Status</p>
                <h2 v-html="formatStakingStatus(stakingUser.status)" class="headline--md mt-4" />
              </div>
            </td>
          </tr>

          <tr v-if="stakingUser.user.blacklist">
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Black List</p>
                <h2 class="headline--md mt-4 text-bold text-error">
                  {{ stakingUser.user.blacklist | format_blacklist_type }}
                </h2>
              </div>
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import stakingStatus from '@/mixins/staking-status'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
const CopyClipboard = () => import('@/components/CopyClipboard')

export default {
  mixins: [stakingStatus, toggleLoading, copyClipboard],

  computed: {
    ...mapState('staking', ['stakingUser'])
  },

  components: {
    CopyClipboard
  },

  methods: {
    ...mapActions('staking', ['getStakingUser']),

    ...mapMutations('staking', ['CLEAR_STAKING_USER'])
  },

  created() {
    this.CLEAR_STAKING_USER()
    this.getStakingUser({ orderId: this.$route.params.id }).finally(() => {
      this.toggleLoading()
    })
  }
}
</script>
