export default {
  methods: {
    formatStakingStatus(status) {
      let stakingStatusEl

      switch (status) {
        case 1:
          stakingStatusEl = '<span class="text-xs">Deposite</span><br><p class="text-uppercase">Initial</p>'
          break
        case 2:
          stakingStatusEl =
            '<span class="text-xs text-warning">Deposite</span><br><p class="text-bold text-warning text-uppercase">Pending</p>'
          break
        case 3:
          stakingStatusEl = '<p class="text-bold text-uppercase text-success text-uppercase">Active</p>'
          break
        case 4:
          stakingStatusEl =
            '<span class="text-xs text-error">Deposite</span><br><p class="text-bold text-error text-uppercase">Failed</p>'
          break
        case 5:
          stakingStatusEl = '<span class="text-xs">Withdraw</span><br><p class="text-bold text-uppercase">Initial</p>'
          break
        case 6:
          stakingStatusEl =
            '<span class="text-xs text-warning">Withdraw</span><br><p class="text-bold text-warning text-uppercase">pending</p>'
          break
        case 7:
          stakingStatusEl = '<p class="text-bold text-gray text-uppercase">Withdrawn</p>'
          break
        case 8:
          stakingStatusEl =
            '<span class="text-xs text-error">Withdraw</span><br><p class="text-bold text-error text-uppercase">Failed</p>'
          break
      }

      return stakingStatusEl
    }
  }
}
